.Suggestions-module__SuggestionsWrapper {
  border-bottom: 1px solid #E1E8F2;
  display: flex;
  flex-direction: column;
}

.Suggestions-module__SuggestionsWrapper > li {
  padding: 16px;
  cursor: pointer;
}

.Suggestions-module__SuggestionsWrapper > li > div > .Suggestions-module__highlighted {
  color: #154f9c;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.Suggestions-module__SuggestionsWrapper > li > div > span {
  color: #acb8c8;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.Suggestions-module__SuggestionsWrapper > li:not(:last-child) {
  padding: 16px;
  /* border-bottom: 1px solid #e1e8f2; */
}
