.UnbxdMobileTextSearch-module__wrapper {
  position: fixed;
  height: calc(100vh - var(--vh, 1vh));
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 9999999;
}

.UnbxdMobileTextSearch-module__header {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #e1e8f2;
  align-items: center;
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__header > img {
  transform: rotate(180deg);
}

.UnbxdMobileTextSearch-module__inputWrapper {
  border: 1px solid #e1e8f2;
  border-radius: 4px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  /* padding: 12px; */
  position: relative;
  height: 48px;
  overflow: hidden;
}

.UnbxdMobileTextSearch-module__inputWrapper > .UnbxdMobileTextSearch-module__input {
  border: none;
  /* flex-grow: 1; */
  width: 100%;
  /* height: 16px; */
  /* font-size: 0; */
  font-size: 17px;
  line-height: 16px;
  color: #154f9c;
  font-weight: 600;
  padding: 11px 12px;
  background-color: #fff;
  opacity: 1; /* required on iOS */
}

.UnbxdMobileTextSearch-module__input:placeholder {
  color: #acb8c8 !important;
}

.UnbxdMobileTextSearch-module__inputWrapper > img {
  position: absolute;
  left: 12px;
  top: calc(50% - 16px / 2);
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__inputWrapper > img {
  left: unset;
  right: 12px;
}

.UnbxdMobileTextSearch-module__inputWrapper > input::-moz-placeholder {
  color: #acb8c8;
}

.UnbxdMobileTextSearch-module__inputWrapper > input::placeholder {
  color: #acb8c8;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.UnbxdMobileTextSearch-module__textSearch {
  /* border: 1px solid #e1e8f2; */
  border: 1px solid #b1bdf9;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
  min-height: 48px;
}

body[dir="rtl"] .UnbxdMobileTextSearch-module__textSearch {
  padding: 12px 16px;
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__textSearch {
  padding: 7px 7px 7px 12px;
}

.UnbxdMobileTextSearch-module__textSearch > span {
  font-size: 14px;
  line-height: 16px;
  /* color: #acb8c8; */
  color: #72a0dd;
  font-weight: 600;
}

.UnbxdMobileTextSearch-module__textSearch > span.UnbxdMobileTextSearch-module__active {
  color: #0e5bc0;
}

.UnbxdMobileTextSearch-module__textSearch .UnbxdMobileTextSearch-module__clear {
  margin-right: auto;
  cursor: pointer;
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__textSearch .UnbxdMobileTextSearch-module__clear {
  margin-right: unset;
  margin-left: auto;
}

.UnbxdMobileTextSearch-module__searchButton {
  width: 55px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c56d4;
  color: #ffffff;
  border: none;
  box-sizing: border-box;
  outline: none !important;
  font-size: 12px;
  font-weight: bold;
}

body[dir="rtl"] .UnbxdMobileTextSearch-module__searchButton,body[dir="rtl"] .UnbxdMobileTextSearch-module__searchIconN {
  margin-right: auto;
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__searchButton,body[dir="ltr"] .UnbxdMobileTextSearch-module__searchIconN {
  margin-left: auto;
}

body[dir="rtl"] .UnbxdMobileTextSearch-module__searchButtonInner {
  margin-left: 7px;
}

body[dir="ltr"] .UnbxdMobileTextSearch-module__searchButtonInner {
  margin-right: 7px;
}
