.HomePage-module__CustomContainer {
  max-width: 1150px;
  margin: 0 auto;
}

.HomePage-module__CustomContainer > [class*="whyBuySection2"] > [class*="container"] {
  margin: 0;
  width: 100%;
}

.HomePage-module__slide {
  position: relative;
  overflow: hidden;
}

.HomePage-module__mt20 {
  margin-top: 80px;
}

/* loaders */
.HomePage-module__heroLoader {
  margin-top: 25px;
  height: 458px;
}

.HomePage-module__paymentsLoader {
  max-width: 566px;
  margin: 0 auto;
  width: 90%;
  height: 52px;
  margin-bottom: 65px;
  margin-top: 45px;
}

.HomePage-module__boxesContainer {
  display: flex;
  flex-wrap: wrap;
}

.HomePage-module__boxesContainer > div {
  width: calc((100% / 4) - 20px);
  margin-bottom: 20px;
  height: 209px;
}
/* end loaders */

.HomePage-module__mTopX {
  margin-top: 60px;
}

.HomePage-module__slidContw {
  position: absolute;
  width: 100%;
  z-index: 2;
  /* bottom: 80px; */
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomePage-module__slidContw > a {
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-weight: bold;
}

.HomePage-module__moreSameCar {
  margin: 0 auto;
  padding: 10px 15px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
}

@media screen and (min-width: 1025px) {
  body[dir="rtl"] .HomePage-module__boxesContainer > div {
    margin-left: 20px;
  }
  body[dir="ltr"] .HomePage-module__boxesContainer > div {
    margin-right: 20px;
  }
  .HomePage-module__dtCm {
    margin-top: 40px;
  }
  .HomePage-module__slide {
    /* height: 458px; */
  }
  .HomePage-module__slide > img {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  }
  .HomePage-module__slide {
    padding-top: 33.22%;
  }
  .HomePage-module__slide > img,
  .HomePage-module__slide > picture > img,
  .HomePage-module__slide > span[class*="lazy-load-image-background"],
  .HomePage-module__slide > span[class*="lazy-load-image-background"] > img {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
@media screen and (max-width: 1024px) {
  .HomePage-module__CustomContainer {
    padding: 0 20px;
    box-sizing: border-box;
  }
  /* .slide {
    height: 196px;
  } */
  /* loaders */
  .HomePage-module__heroLoader {
    height: 196px;
  }

  .HomePage-module__paymentsLoader {
    height: 72px;
  }

  .HomePage-module__boxesContainer > div {
    width: 100%;
    margin-bottom: 20px;
    height: 125px !important;
  }
  /* end loaders */
  .HomePage-module__dtCm {
    margin-bottom: 40px;
  }
  .HomePage-module__slide > img {
    /* position: static;
    width: 100%; */
  }

  .HomePage-module__lineSepMob {
    background-color: #e2e2e2;
    width: 100%;
    height: 1px;
    margin-top: 15px;
  }
  .HomePage-module__slide {
    padding-top: 94.1%;
  }
  .HomePage-module__slide > img,
  .HomePage-module__slide > picture > img,
  .HomePage-module__slide > span[class*="lazy-load-image-background"],
  .HomePage-module__slide > span[class*="lazy-load-image-background"] > img {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .HomePage-module__undoCustomWidth {
    padding: 0;
  }
}
