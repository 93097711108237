.Header-module__logo {
  margin: 0 30px;
}

.Header-module__siteHeader {
  box-shadow: 0 0 10px rgba(188, 188, 188, 0.4);
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 9999 !important;
  background-color: #fff;
}

.Header-module__siteHeader > .Header-module__innerHdr > ul {
  display: flex;
  align-items: center;
  gap: 30px;
}

.Header-module__siteHeader > .Header-module__innerHdr > ul > li > a {
  display: block;
  transition: 0.3s;
}

.Header-module__siteHeader > .Header-module__innerHdr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body[dir="rtl"] .Header-module__siteHeader > .Header-module__innerHdr > ul > li:not(:first-child) {
  margin-right: 30px;
}

body[dir="ltr"] .Header-module__siteHeader > .Header-module__innerHdr > ul > li:not(:first-child) {
  margin-left: 30px;
}

.Header-module__siteHeader > .Header-module__innerHdr > ul > li > a {
  display: block;
  transition: 0.3s;
}

.Header-module__sellCarNewBtn > a {
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
}

.Header-module__sellCarBtn {
  color: #ec6234;
  border: solid 1px #ec6234;
}

.Header-module__sellCarBtn:hover {
  background-color: #ec6234;
  color: #ffffff;
}

.Header-module__disableLink {
  pointer-events: none;
}

.Header-module__revampLink a,
.Header-module__revampLink span {
  color: #6c7a8d;
  cursor: pointer;
}

.Header-module__revampLink.Header-module__activeLink a,
.Header-module__revampLink.Header-module__activeLink span {
  color: #154f9c;
  font-weight: bold;
}

.Header-module__revampLink.Header-module__activeLink {
  position: relative;
}

.Header-module__revampLink.Header-module__activeLink::after {
  content: "";
  background-color: #fe561c;
  border-radius: 1px;
  height: 3px;
  width: calc(100% + 30px);
  display: block;
  right: -15px;
  position: absolute;
  bottom: -30px;
}

.Header-module__mobileTabs {
  display: flex;
  margin-top: 14px;
  border-top: solid 1px #e1e8f2;
  border-bottom: solid 1px #e1e8f2;
  height: 47px;
  align-items: center;
  justify-content: space-around;
}

.Header-module__logoContainer {
  display: flex;
}

.Header-module__fleetContainer {
  width: 135px;
}

.Header-module__fleetContainer > img {
  width: 100%;
}

.Header-module__logoContainer > img:not(.Header-module__fleetLogo) {
  width: 80px;
  margin-left: 15px;
}

.Header-module__callUsHdr {
  width: 157px;
  height: 41px;
  border: solid #00b93d 1px;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  line-height: 37px;
  font-size: 16px;
  font-weight: bold;
  color: #00b93d;
  text-decoration: none !important;
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 20px;
}

.Header-module__callUsHdr:hover {
  color: #00b93d;
}

body[dir="ltr"] .Header-module__trackingLink > img {
  transform: rotateY(180deg);
}
.Header-module__trackingLink {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  color: #484848;
  padding: 5px 0;
}

.Header-module__newBtnOrng {
}

body[dir="rtl"] .Header-module__mobileBackBlueArr {
  margin-left: 10px;
}

body[dir="ltr"] .Header-module__mobileBackBlueArr {
  margin-right: 10px;
}

body[dir="ltr"] .Header-module__mobileBackBlueArr > img {
  transform: rotateY(180deg);
}

.Header-module__custommobileHeader {
  position: sticky;
  top: 0;
  z-index: 99999;
}

.Header-module__back {
  background-color: transparent;
  border: none;
}

/* .back > img {
  transform: rotate(270deg);
} */

.Header-module__backFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Header-module__mobHasTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #154f9c;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .Header-module__logo {
    margin: 0 20px;
  }

  .Header-module__logoSizeRev {
    width: 57px !important;
    margin: 0 !important;
  }
  .Header-module__MenuIcnSizeRev {
    width: 20px;
  }
  .Header-module__lastLiRev {
    margin: 0 !important;
  }
  .Header-module__revampedSearch {
    padding-bottom: 0px !important;
    box-shadow: none !important;
  }
  .Header-module__revampLink a,
  .Header-module__revampLink span {
    font-size: 12px;
    line-height: 16px;
  }
  .Header-module__revampLink.Header-module__activeLink::after {
    width: 100px;
    right: calc(50% - 50px);
    bottom: -15px;
  }
  .Header-module__logoContainer > img:not(.Header-module__fleetLogo) {
    width: 55px !important;
    /* margin: 0 !important; */
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 10px;
  }

  .Header-module__fleetContainer {
    width: 120px;
  }

  .Header-module__callUsHdr {
    width: 140px;
  }
  .Header-module__trackingLink {
    font-size: 18px;
  }
  .Header-module__newBtnOrng > a {
    font-size: 12px;
    line-height: 16px;
    position: relative;
    top: -2px;
    padding: 4px 8px !important;
  }

  /* body[dir="ltr"] .newBtnOrng > a {
    right: -15px;
  } */
  .Header-module__mobileTabs {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .Header-module__straightHeader {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .Header-module__siteHeader {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: none !important;
    padding: 16px 0px;
  }
  .Header-module__siteHeader > .Header-module__innerHdr {
    min-height: 40px;
  }
  .Header-module__siteHeader > .Header-module__innerHdr > ul > li:not(:last-child) {
    display: none;
  }
  .Header-module__sellCarNewBtn:not(.Header-module__hideBtn) {
    display: inline-block !important;
  }
  .Header-module__siteHeader > .Header-module__innerHdr > ul {
    gap: 0;
  }

  body[dir="ltr"] .Header-module__revampLink span {
    font-size: 14px;
  }

  body[dir="ltr"] .Header-module__revampLink.Header-module__activeLink::after {
    bottom: -11px;
  }
  body[dir="rtl"] .Header-module__sellCarNewBtn > a {
    font-size: 9px;
    line-height: 16px;
  }
  .Header-module__sellCarNewBtn > a {
    font-weight: 700;
  }
  .Header-module__revampedHeader {
    padding-bottom: 0 !important;
    box-shadow: none !important;
  }
}
