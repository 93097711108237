.UnbxdTextSearch-module__wrapper {
  max-width: 760px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #b1bdf9;
  background: #fff;
  width: 45%;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.UnbxdTextSearch-module__wrapper > input {
  flex-grow: 1;
  color: #154f9c;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border: unset;
}

.UnbxdTextSearch-module__wrapper > input::-moz-placeholder {
  color: #72a0dd;
}

.UnbxdTextSearch-module__wrapper > input::placeholder {
  color: #72a0dd;
}

.UnbxdTextSearch-module__wrapper > button {
  border: unset;
  border-radius: 4px;
  background: #3c56d4;
  display: flex;
  padding: 4px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .UnbxdTextSearch-module__wrapper {
    min-height: 47px;
    height: 47px;

  }
  body[dir="rtl"] .UnbxdTextSearch-module__wrapper {
    margin-left: auto;
    margin-right: 32px;
  }
  body[dir="ltr"] .UnbxdTextSearch-module__wrapper {
    margin-right: auto;
    margin-left: 32px;
  }
}
