
.RecommendationSlider-module__container {
    max-width: 1158px;
    margin: 70px auto 0;
}

.RecommendationSlider-module__big {
    color: #154f9c;
    font-size: 30px;
    line-height: 56px;
    font-weight: bold;
    position: relative;
    padding-bottom: 30px;
    text-align: center;
    word-wrap: break-word;
}

/* .big:after {
    content: "";
    width: 104px;
    height: 5px;
    background-color: #ec6234;
    position: absolute;
    left: calc(50% - 104px / 2);
    bottom: 0;
} */

.RecommendationSlider-module__cardsWrapper {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(273px, 1fr));
    grid-gap: 20px;
    list-style-type: none;
    padding-bottom: 20px;
}


.RecommendationSlider-module__card > div:first-child {
    height: 100% !important;
}

.RecommendationSlider-module__showAllRecommendedCars {
    display: none;
}

.RecommendationSlider-module__showRecommendedCarsSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.RecommendationSlider-module__showAllRecommendedCarsButton {
    border-radius: 4px;
    background: #FE561C;
    width: 250px;
    height: 48px;
    padding: 16px;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    outline: 0;
    border: 0;
    cursor: pointer;
}

.RecommendationSlider-module__showAllRecommendedCarsButton:hover {
    color: #fff;
}

body[dir="ltr"] .RecommendationSlider-module__arrowLeft {
    transform: scaleX(-1);
}

/** 1024px **/
@media screen and (max-width: 1024px) {
    .RecommendationSlider-module__container {
        width: 100%;
        margin-top: 0px !important;
    }

    .RecommendationSlider-module__cardsWrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding-inline: 20px;
        scroll-snap-type: x mandatory;
    }

    .RecommendationSlider-module__card {
        flex: 0 0 auto;
        scroll-snap-align: center;
        min-height: 100%;
        width: 300px;
    }

    .RecommendationSlider-module__showAllRecommendedCars {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #3C56D4;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        width: 165px;
        flex: 0 0 auto;
        scroll-snap-align: center;
        padding: 24px;
        gap: 4px;
    }

    .RecommendationSlider-module__showAllText {
        color: #FFF;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .RecommendationSlider-module__showRecommendedCarsSection {
        display: none;
    }
}

@media (min-width: 992px) {
    .RecommendationSlider-module__cardsWrapperMin {
        grid-template-columns: repeat(auto-fit, minmax(273px, min-content));
        place-content: center;
    }
}

/** 768px **/
@media screen and (max-width: 767px) {
    .RecommendationSlider-module__big {
        font-size: 16px;
        padding-bottom: 0;
        text-align: right;
        line-height: 24px;
        padding-right: 20px;
        padding-left: 20px;
    }

    body[dir="ltr"] .RecommendationSlider-module__big {
        text-align: left;
    }

    .RecommendationSlider-module__big:after {
        display: none;
    }

    .RecommendationSlider-module__cardsWrapper {
        margin-top: 20px;
    }
}

/** sekeleton **/
/* UnbxdCardSkeleton.module.css */
@keyframes RecommendationSlider-module__loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.RecommendationSlider-module__cardItem {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.RecommendationSlider-module__imagePlaceholder {
    width: 100%;
    height: 225px;
    border-radius: 8px 8px 0 0;
    animation: RecommendationSlider-module__loading 1s linear infinite alternate;
}

.RecommendationSlider-module__textPlaceholder {
    width: 80%;
    height: 20px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: RecommendationSlider-module__loading 1s linear infinite alternate;
}

.RecommendationSlider-module__titlePlaceholder {
    width: 65%;
    height: 15px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: RecommendationSlider-module__loading 1s linear infinite alternate;
}

.RecommendationSlider-module__cardBody {
    padding: 12px;
}

.RecommendationSlider-module__tag {
    width: 40px;
    border-radius: 4px;
    height: 20px;
    animation: RecommendationSlider-module__loading 1s linear infinite alternate;
    margin-bottom: 4px;
}

.RecommendationSlider-module__priceText {
    height: 15px;
    margin-top: 40px;
    width: 20%;
    border-radius: 4px;
    animation: RecommendationSlider-module__loading 1s linear infinite alternate;
}

.RecommendationSlider-module__cardFooter {
    border-top: 1px solid hsl(200, 20%, 95%);
}

.RecommendationSlider-module__tagWrapper {
    padding: 12px;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

/** skeleton mobile **/
@media screen and (max-width: 767px) {
    .RecommendationSlider-module__cardItem {
        flex: 0 0 auto;
        scroll-snap-align: center;
        min-height: 100%;
        width: 300px;
    }

    .RecommendationSlider-module__showAllLinkMobile {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }
}
